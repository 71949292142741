/* 

    font-family: 'Anton', sans-serif;

    font-family: 'Glegoo', serif;

 */
body{
    margin: 0;
    padding: 0;
    font-family: 'Glegoo', serif;
    overflow-x: hidden;
}

.text-center{
    text-align: center;
}


.logo-drawer{
    width: 8em;
    height: auto;
}

.section-title{
    font-size: 3em;
    font-family: 'Glegoo', serif;
    opacity: 1 !important;
    color: #1A315C;
}

/* HOME */

.home-content{
    padding-top: 300px;
}
.home-content h2{
    color: #ffffff;
}

.main{
    font-size: 5vh;;
    font-family: 'Glegoo', serif;

}

.sub{
    font-size: 4vh;
    color: #e4e4e4;
    font-family: 'Glegoo', serif;

}

/* ABOUT */

.lead-text{
    text-align: justify;
    font-size: 1.3em;
}

.lead-text-ap{
    text-align: justify;
    font-size: 1.3em;
    color: #ffffff;
}


/* Footer */

.text-footer{
    color: #ffffff;
    font-family: 'Glegoo', serif;

}


/* FEATURES */

.feature-list{
    color: #1A315C;
    font-size: 1.4rem;
}

/* Applications */


.application-list{
    color: #ffffff;
    font-size: 1.4rem;
}
